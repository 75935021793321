<template>
    <AppContainer>
        <AddDeal/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
import AddDeal from '@/components/dashboard/deals/AddDeal.vue'
export default {
  components: {
    AppContainer,
    AddDeal
  }
}
</script>