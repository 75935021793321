<template>
    <div class="email-campaign">
        <div class="step1-area">
            <div class="email-deatils">
                <h3>Create New Deal</h3>
                <form action="">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Deal title</label>
                                <input v-model="store.deal_.title" :class="[v$.title.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="What the Deal title?" />
                            </div>
                            <div class="form-group">
                                <label>Start Date</label>
                                <Datepicker :input-class-name="v$.start_date.$error ? 'border-danger': ''" :minDate="new Date()" v-model="store.deal_.start_date"  :enableTimePicker="false" :autoApply="true" placeholder="Select Start Date" ></Datepicker>
                            </div>
                            <div class="form-group">
                                <label>Expiry Date</label>
                                <Datepicker :input-class-name="v$.expire_date.$error ? 'border-danger': ''" :minDate="new Date()" v-model="store.deal_.expire_date" :enableTimePicker="false" :autoApply="true" placeholder="Select Expire Date"></Datepicker>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="banner-img" :class="[v$.banner.$error ? 'border-danger': '']">
                                <img :src="imgDataUrl">
                                <a href="javascript:void(0)" class="camera_icon bg-white" title="Upload Profile Image" @click="cropper = !cropper">
                                    <fa icon="camera" />
                                </a>
                                <myUpload field="img" @crop-success="cropSuccess" v-model="cropper" :width="800" :height="300" :params="{token:'1234567890',name:'banner'}" :headers="{smail: '*_~'}" img-format="jpg" langType="en" :noCircle="true"></myUpload>
                            </div>
                            <input type="file" name="upload" id="upload_image" class="form-control upload-box p-1 d-none" />
                        </div>
                    </div>

                    <div class="form-row">
                        <div class="form-group col-12">
                            <label>Deal Description</label>
                            <textarea v-model="store.deal_.description" :class="[v$.description.$error ? 'border-danger': '']" rows="5" style="resize: none;" class="form-control tnymc" id="mce_0" placeholder="Please include brief summary of your deal here."></textarea>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <label>Select Professional </label>
                            <select v-model="store.deal_.employee" :class="[v$.employee.$error ? 'border-danger': '']" class="form-control" v-on:change="getServices">
                                <option value="">Choose employee</option>
                                <option v-for="employee in employees" :key="employee.id" :value="employee">{{employee.user.name}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="form-row">
                        <div v-for="(service,index) in store.deal_.services" :key="service.id" class="form-group col-sm-6 col-md-6 col-lg-3">
                            <label><span v-if="index == 0">Select Services</span> </label>
                            <label class="con-company" :class="service.selected?'currnt':''">
                                {{service.title}}
                                <input v-model="service.selected" :class="[v$.services.$error ? 'border-danger': '']" :true-value="true" :false-value="false" type="checkbox" name="checkbox" />
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <!-- <div class="form-row" v-if="store.deal_.services.length > 0">
                        <div class="form-group col-12" v-show="selectedServices.length > 0">
                            <div class="form-group">
                                <label class="switch mr-2">
                                    <input type="checkbox" v-model="store.deal_.package">
                                    <span class="slider round"></span>
                                </label>
                                Make a package of these services
                            </div>
                        </div>
                    </div> -->
                    <div class="row" v-show="store.deal_.services.length > 0">
                        <div class="col-12">
                            <div class="leads-table-area report-table-area" style="box-shadow: none;">
                                <table class="display" id="servicesTable">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Title</th>
                                            <th>Duration</th>
                                            <th>Cost</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="store.deal_.services.length > 0">
                                        <tr v-for="(serv,index) in selectedServices" :key="serv.id">
                                            <td>{{index+1}}</td>
                                            <td>{{serv.title}}</td>
                                            <td>{{serv.duration}} min</td>
                                            <td>${{serv.cost}}</td>
                                        </tr>
                                        <tr v-show="selectedServices.length == 0">
                                            <td colspan="4" class="text-center">No Service</td>
                                        </tr>
                                    </tbody>
                                    <tfoot v-if="store.deal_.services.length > 0">
                                        <tr v-show="selectedServices.length > 0">
                                            <th colspan="3" class="text-right">Total:</th>
                                            <th>${{selectedServicesSum}}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-row">
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <label>Discount Type </label>
                            <select v-model="store.deal_.discount_type" class="form-control">
                                <option value="percent">% Percentage</option>
                                <option value="fixed">$ Fixed</option>
                            </select>
                        </div>
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <label>Discount Value</label>
                            <input v-model="store.deal_.discount" :class="[v$.discount.$error ? 'border-danger': '']" type="text" class="form-control" placeholder="What the Deal Type Value?" />
                        </div>
                    </div>
                    <div class="form-row mb-4">
                        <div class="form-group col-sm-6 col-md-6 col-lg-6">
                            <label>Status </label>
                            <select v-model="store.deal_.status" class="form-control">
                                <option value="1">Active</option>
                                <option value="0">Inactive</option>
                            </select>
                        </div>
                    </div>
                </form>
            </div>

            <div class="modal-footer campaign-footer d-flex">
                <router-link :to="{name:'dealsList'}" class="add-btn"> Cancel</router-link>
                <button class="rem-btn" type="button" :disabled="isLoading" v-on:click="addDeal">
                    <span v-if="isLoading" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                    <span v-if="isLoading">Loading...</span>
                    <span v-if="!isLoading">Save</span>
                </button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, computed, ref } from 'vue'
import $ from "jquery";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import useVuelidate from '@vuelidate/core'
import { required, integer } from '@vuelidate/validators'
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";
import { useDealsStore } from '@/stores/deals'
import { useEmployeesStore } from '@/stores/employees'
import { useServicesStore } from '@/stores/services'
import { useStorage } from "vue3-storage";

// Datatable
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
// Image Croper
import myUpload from 'vue-image-crop-upload';

const store = useDealsStore()
const employeeStore = useEmployeesStore()
const servicesStore = useServicesStore()
const toast = useToast();
const router = useRouter()
const employees = ref([])
const cropper = ref(false)
const imgDataUrl = ref('https://s3.us-east-2.amazonaws.com/images.ondaq.com/deals/default.jpg')
const storage = useStorage()

store.deal_.business_id = storage.getStorageSync('business').active_business.id

// Add form validation
const formData = store.deal_
const rules = {
    title:{ required },
    start_date:{ required },
    expire_date:{ required },
    description:{ required },
    employee:{ required },
    services:{ required },
    discount_type:{ required },
    discount:{ required, integer },
    banner:{ required }
}
const v$ = useVuelidate(rules, formData)

onMounted(() => {
    $("#servicesTable").addClass("nowrap").dataTable({
        ordering: false,
        responsive: true,
        fixedHeader: true,
        language : {
            "zeroRecords": " "             
        }
    });
})

const selectedServices = computed(() => {
    return store.deal_.services.filter(i => i.selected === true)
})
const selectedServicesSum = computed(() => {
    var data = store.deal_.services.filter(i => i.selected === true)
    return data.reduce((acc, item) => parseFloat(acc) + parseFloat(item.cost), 0).toFixed(2);
})

const getEmployees = async () => {
    const resp = await employeeStore.getEmployees()
    // console.log(resp)
    if (resp.status === 'success') {
        employees.value = resp.data
    }else{
        toast.error(resp.message);
    }  
}
getEmployees()

const getServices = async () => {
    const resp = await servicesStore.getEmployeeServices(store.deal_.employee.user.id)
    // console.log(resp)
    if (resp.status === 'success') {
        var data = []
        resp.data.forEach((serv) => {
            data.push({ 
                title: serv.service.title, 
                business_id: serv.business_id, 
                employee_id: serv.id, 
                service_id: serv.service.id,
                cost: serv.business_service.cost,
                duration: serv.business_service.duration,
                business_service_id: serv.business_service.id,
                selected: false
            })
        });
        store.deal_.services = data
    }else{
        toast.error(resp.message);
    }  
}

const cropSuccess = (imgUrl, field) => {
    console.log('-------- crop success --------'+field);
    imgDataUrl.value = imgUrl
    store.deal_.banner = imgUrl
}

const isLoading = ref(false);
const addDeal = async () => {
    console.log(store.deal_)
    v$.value.$touch()
    if (!v$.value.$error) {
        isLoading.value = true
        const resp = await store.addNewDeal()
        //console.log(resp)
        if (resp.status === 'success') {
            toast.success("Deal successfully created");
            router.push({name: 'dealsList'})
        }else{
            toast.error(resp.message);
        }
        isLoading.value = false
    }
};
</script>

<style type="text/css">
    .banner-img{
        position: relative;
        text-align: center;
        margin: 0 auto;
        border: 1px solid #eee;
        border-radius: 5px
    }
    .banner-img img{
        max-height: 230px;
        max-width: 425px;
        margin-top: 5px
    }
    .camera_icon {
        position: absolute;
        font-size: 20px;
        right: 5px;
        bottom: 5px;
        border-radius: 50%;
        width: 35px;
        height: 33px;
        border: 1px solid #6e317a;
        color: #6e317a
    }
    table.dataTable tfoot th, table.dataTable tfoot td{
        padding: 15px 10px
    }
    .vicp-preview{
        height: 220px !important;
    }
    .vicp-preview-item{
        padding: 0 !important;
        height: 220px !important;
        width: 100% !important;
        max-width: 287px;
        margin: 0 !important;
    }
    .vicp-preview-item img{
        height: auto !important;
        width: 100% !important;
        position: inherit !important;

    }
    .vicp-preview-item span{
        bottom: 10px !important;
    }
</style>
<style scoped>
@import "../../../assets/css/dashboard/dataTables.css";
</style>